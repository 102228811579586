export class StringKeyValuePair {
    constructor(public key: string, public value: string) { }
}

export class KeyValuePair<T, Y>{
    constructor(public key: T, public value: Y) { }
}

export class WindowSize {
    width: number;
    height: number;
}

export class ImageSize {
    constructor(public width: number, public height: number) { }
}
